<template>
  <div id="items">
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">Notícias</h4>
            <div class="d-flex">
              <router-link
                to="/news-category"
                class="btn mr-3 d-flex align-items-center btn-secondary"
              >
                <CIcon name="cil-bullhorn" class="mr-2" />
                Categorias
              </router-link>
              <router-link
                to="/news/create"
                class="btn btn-primary d-flex align-items-center"
              >
                <CIcon name="cil-library-add" class="mr-2" />
                Incluir
              </router-link>
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol col="12">
                <Search @search="updateSearch" />
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <CRow>
                  <CCol md="4" lg="4" xl="3">
                    <div class="form-group">
                      <Multiselect
                        :value="
                          statusOptions.find(
                            (option) => option.value === query.status
                          )
                        "
                        :options="statusOptions"
                        @select="(selected) => (query.status = selected.value)"
                      />
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="12">
                <DataTable
                  :sorter="query.sorter"
                  :items="datatable.items"
                  :fields="datatable.fields"
                  :enable-export="false"
                  base-route="/news/edit/"
                  @sorterValue="(event) => (query.sorter = event)"
                  @row-clicked="handleRowClick"
                  @update:checked="updateStatus"
                >
                  <template #actions="{ id }">
                    <DatatableEdit :url="`/news/edit/${id}`" />
                    <DatatableRemove
                      @row-clicked="handleRowClick"
                      @deleted="deleteItem"
                    />
                  </template>
                </DataTable>
              </CCol>
            </CRow>
            <CRow class="mt-2">
              <CCol lg="12">
                <Pagination
                  :page="query.page"
                  :per-page="query.perpage"
                  :pages="pagination.pages"
                  :total="pagination.total"
                  :items="pagination.items"
                  @update:active-page="(page) => (query.page = page)"
                  @update:perpage="(perpage) => (query.perpage = perpage)"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DataTable from '@/components/ui/Datatable'
import DatatableEdit from '@/components/ui/DatatableActions/Edit'
import DatatableRemove from '@/components/ui/DatatableActions/Remove'
import Pagination from '@/components/ui/Pagination'
import Search from '@/components/ui/Search'
import Multiselect from '@/components/ui/Multiselect'
import Service from '@/services/news.service'

export default {
  metaInfo: {
    title: 'Notícias',
    titleTemplate: 'Manager - Rodonaves Iveco - %s'
  },

  components: {
    DataTable,
    DatatableEdit,
    DatatableRemove,
    Pagination,
    Search,
    Multiselect
  },

  data() {
    return {
      datatable: {
        items: [],
        fields: [
          {
            key: 'status',
            label: 'Status',
            _style: 'width: 12%;',
            sorter: false
          },
          {
            key: 'shortTitle',
            label: 'Título',
            sorter: true
          },
          {
            key: 'category.title',
            label: 'Categoria',
            sorter: false
          },
          {
            key: 'publicationAtFormated',
            label: 'Data',
            sorter: false
          },
          {
            key: 'actions',
            label: 'Ações',
            sorter: false,
            _style: 'width: 12%;'
          }
        ]
      },
      query: {
        search: '',
        page: 1,
        perpage: localStorage.getItem('perPageItem')
          ? parseFloat(localStorage.getItem('perPageItem'))
          : 10,
        pagination: true,
        status: null,
        sorter: {
          asc: false,
          column: ''
        }
      },
      pagination: {
        total: 0,
        pages: 0,
        items: 0
      },
      statusOptions: [
        {
          value: null,
          label: 'Todos os status'
        },
        {
          label: 'Ativo',
          value: true
        },
        {
          label: 'Inativo',
          value: false
        }
      ],
      rowCliked: {}
    }
  },

  watch: {
    query: {
      handler: 'updateDataTable',
      deep: true
    }
  },

  async created() {
    await this.updateDataTable()
  },

  methods: {
    async updateDataTable() {
      const response = await Service.index(this.query)
      const { data, pagination } = response

      if (data) {
        this.pagination = {
          ...pagination,
          pages: pagination.lastPage,
          items: data.length
        }

        const newItems = response.data.map((item) => {
          return {
            ...item
          }
        })

        this.datatable.items = newItems
      }
    },

    async updateSearch(searchText) {
      this.query.search = searchText
      this.query.page = 1

      await this.updateDataTable()
    },

    handleRowClick(event) {
      this.rowCliked = event
    },

    async updateStatus(event) {
      const response = await Service.updateStatus(this.rowCliked, event)

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          type: 'success',
          text: 'Status alterado com sucesso'
        })
        await this.updateDataTable()
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          text: 'Ocorreu um erro ao atualizar o status, por favor tente novamente!'
        })
      }
    },

    async deleteItem() {
      const response = await Service.deleteItem(this.rowCliked)

      if (response.status === 200) {
        this.$notify({
          group: 'notifications',
          type: 'success',
          text: 'Item excluído com sucesso!'
        })
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          text: 'Não foi possivel excluir o item, por favor tente novamente'
        })
      }

      await this.updateDataTable()
    }
  }
}
</script>

<style lang="scss">
#items {
  .orderButton {
    margin-right: 10px;
  }

  .thumb {
    width: 80px !important;
  }
}
</style>
